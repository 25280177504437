import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './include';

export const Testimonials =()=> {


    const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "select imageUrl, posterName, message from tbl_testimonials order by ID DESC "
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  useEffect(()=>{
    fetchContent()
 },[]);

return  <section className="testimonial-section section-padding ash-white-bg">
<div className="container">
    <div className="row">
        <div className="col-md-12">
            <div className="section-heading text-center">
                <h2 className="section-title wow fadeInUpXsd yellow" data-wow-duration=".7s" data-wow-delay=".1s">OUR   <span className="base-color">SUCCESS STORIES</span></h2>
                <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s">Our Beneficiaries and  participants expressing themselves </span>
                <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s"></div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12">
            <div className="fund-testimonial-carousel wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".3s">


             {content.length!==0?content.map((item, id)=>    <div key={id} className="fund-testimonial">
                    <div className="fund-testimonial__quote base-bg">
                        {item.message.slice(0, 250)}
                    </div>
                    <div className="fund-testimonial__author">
                        <div className="fund-testimonial__author-image">
                            <img src={ServerImagesUrl+'/testimonials/'+item.imageUrl} alt="" />
                        </div>
                        <div className="fund-testimonial__author-text">
                            <h4 className="fund-testimonial__author-name">{item.posterName}</h4>
                            <span className="fund-testimonial__designation">Participant</span>
                        </div>
                    </div>
                </div>):''}

            </div>
        </div>
    </div>
</div>
</section>}