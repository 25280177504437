import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './include';


 const Slider =()=>{
    const [content, setContent] = useState([])

    const fetchContent = ()=>{
        var sql = "SELECT imageUrl, title, caption, action, link from tbl_slider order by ID DESC limit 4"

      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>{
         console.log(result.data)
          if(Array.isArray(result.data)){
            setContent(result.data)
          }
      }
      )
  }


  useEffect(()=>{
    fetchContent()
 },[]); 


      return      <div data-scroll-index="0" id="rev_slider_1052_1_wrapper" className="all-text-white rev_slider_wrapper fullscreen-container" data-alias="web-product-dark122" data-source="gallery">
         
             <div id="rev_slider_1052_1" className="rev_slider fullscreenbanner"  data-version="5.3.0.2">
         <ul>	
 

        {/*  {content.length!==0?content.map((item, id)=>     <li key={id} data-index="rs-2947" data-transition="fade" data-slotamount="1" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="1500"  data-rotate="0"  data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off"  data-title="Intro" style={{backgroundImage:`url(${ServerImagesUrl+"/slides/"+item.imageUrl})`, backgroundRepeat:'round'}}>
               
                 <img src={ServerImagesUrl+'/slides/'+item.imageUrl} alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
               
                 <div className="tp-caption tp-resizeme"
                      id={`slide-2947-layer-${id}`}
                     data-x="['middle','middle','middle','middle']"
                     data-hoffset="['-237','-217',0,'0']"
                     data-y="['middle','middle','middle','middle']"
                     data-voffset="['-60','-60','-60','-60']"
                     data-fontsize="['48','48','48','30']"
                     data-lineheight="['60','60','50','30']"
                     data-width="['710','710','none','none']"
                     data-height="none"
                     data-whitespace="wrap"
                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                     data-text-align="['left','left','center','center']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]"
                     ><span className="slider-title">{item.title} <br/></span></div>
               
                 <div className="tp-caption tp-resizeme slider-sub-heading-wrap"
                     id="slide-2947-layer-10"
                     data-x="['middle','middle','middle','middle']"
                     data-hoffset="['-237','-217',0,'0']"
                     data-y="['middle','middle','middle','middle']"
                     data-voffset="['40','40','40','20']"
                     data-fontsize="['15','15','15','15']"
                     data-lineheight="['24','24','24','24']"
                     data-width="['710','710','510','300']"
                     data-height="none"
                     data-whitespace="wrap"
                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1250,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                     data-text-align="['left','left','center','center']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]">
                     <span className="slider-sub-heading">{item.caption} </span></div>
               

                
                 <div className="tp-caption  tp-resizeme slider-button"
                     id="slide-2947-layer-8"
                     data-x="['middle','middle','middle','middle']"
                     data-hoffset="['-225','-147',0,'0']"
                     data-y="['middle','middle','middle','middle']"
                     data-voffset="['135','125','115','115']"
                     data-width="['710','710','none','none']"
                     data-height="['48','48','48','48']"
                     data-whitespace="nowrap"
                     data-type="text"
                     data-actions='[{"event":"click","action":"jumptoslide","slide":"rs-2947","delay":""}]'
                     data-responsive_offset="on"
                     data-responsive="off"
                     data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;"}]'
                     data-text-align="['left','left','center','center']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]" >
                      {item.action==='readMore'?
                         <a href={item.link} className="btn">Read More</a>:item.action==='donate'?<a href='/donation' className="btn blob">Donate Now</a>:''}
                 </div>
             </li>):""} */}

 

             <li data-index="rs-2948" data-transition="fade" data-slotamount="1" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="1500"  data-rotate="0"  data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off"  data-title="Intro" style={{backgroundImage:content.length!==0?`url(${ServerImagesUrl+"/slides/"+content[1].imageUrl})`:'', backgroundRepeat:'round'}}>
               
               <img src={content.length!==0?ServerImagesUrl+'/slides/'+content[1].imageUrl:''} alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
             
               <div className="tp-caption tp-resizeme"
                    id="slide-2947-layer-7"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-237','-217',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['-60','-60','-60','-60']"
                   data-fontsize="['48','48','48','30']"
                   data-lineheight="['60','60','50','30']"
                   data-width="['710','710','none','none']"
                   data-height="none"
                   data-whitespace="wrap"
                   data-type="text"
                   data-responsive_offset="on"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]"
                   ><span className="slider-title">{content.length!==0?content[1].title:''} <br/></span></div>
             
               <div className="tp-caption tp-resizeme slider-sub-heading-wrap"
                   id="slide-2947-layer-10"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-237','-217',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['40','40','40','20']"
                   data-fontsize="['15','15','15','15']"
                   data-lineheight="['24','24','24','24']"
                   data-width="['710','710','510','300']"
                   data-height="none"
                   data-whitespace="wrap"
                   data-type="text"
                   data-responsive_offset="on"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1250,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]">
                   <span className="slider-sub-heading">{content.length!==0?content[1].caption:''} </span></div>
             

              
               <div className="tp-caption  tp-resizeme slider-button"
                   id="slide-2947-layer-8"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-225','-147',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['135','125','115','115']"
                   data-width="['710','710','none','none']"
                   data-height="['48','48','48','48']"
                   data-whitespace="nowrap"
                   data-type="text"
                   data-actions='[{"event":"click","action":"jumptoslide","slide":"rs-2947","delay":""}]'
                   data-responsive_offset="on"
                   data-responsive="off"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]" >
                    {content.length!==0?content[1].action==='readMore'?
                       <a href={content[1].link} className="btn">Read More</a>:content[1].action==='donate'?<a href='/donation' className="btn blob">Donate Now</a>:'':''}
               </div>
           </li>

           <li data-index="rs-2949" data-transition="fade" data-slotamount="1" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="1500"  data-rotate="0"  data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off"  data-title="Intro" style={{backgroundImage:content.length!==0?`url(${ServerImagesUrl+"/slides/"+content[2].imageUrl})`:'', backgroundRepeat:'round'}}>
               
               <img src={content.length!==0?ServerImagesUrl+'/slides/'+content[2].imageUrl:''} alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
             
               <div className="tp-caption tp-resizeme"
                    id="slide-2947-layer-7"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-237','-217',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['-60','-60','-60','-60']"
                   data-fontsize="['48','48','48','30']"
                   data-lineheight="['60','60','50','30']"
                   data-width="['710','710','none','none']"
                   data-height="none"
                   data-whitespace="wrap"
                   data-type="text"
                   data-responsive_offset="on"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]"
                   ><span className="slider-title">{content.length!==0?content[2].title:''} <br/></span></div>
             
               <div className="tp-caption tp-resizeme slider-sub-heading-wrap"
                   id="slide-2947-layer-10"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-237','-217',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['40','40','40','20']"
                   data-fontsize="['15','15','15','15']"
                   data-lineheight="['24','24','24','24']"
                   data-width="['710','710','510','300']"
                   data-height="none"
                   data-whitespace="wrap"
                   data-type="text"
                   data-responsive_offset="on"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1250,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]">
                   <span className="slider-sub-heading">{content.length!==0?content[2].caption:''} </span></div>
             

              
               <div className="tp-caption  tp-resizeme slider-button"
                   id="slide-2949-layer-8"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-225','-147',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['135','125','115','115']"
                   data-width="['710','710','none','none']"
                   data-height="['48','48','48','48']"
                   data-whitespace="nowrap"
                   data-type="text"
                   data-actions='[{"event":"click","action":"jumptoslide","slide":"rs-2947","delay":""}]'
                   data-responsive_offset="on"
                   data-responsive="off"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]" >
                    {content.length!==0?content[2].action==='readMore'?
                       <a href={content[2].link} className="btn">Read More</a>:content[2].action==='donate'?<a href='/donation' className="btn blob">Donate Now</a>:'':''}
               </div>
           </li>


           <li data-index="rs-2950" data-transition="fade" data-slotamount="1" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="1500"  data-rotate="0"  data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off"  data-title="Intro" style={{backgroundImage:content.length!==0?`url(${ServerImagesUrl+"/slides/"+content[3].imageUrl})`:'', backgroundRepeat:'round'}}>
               
               <img src={content.length!==0?ServerImagesUrl+'/slides/'+content[3].imageUrl:''} alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
             
               <div className="tp-caption tp-resizeme"
                    id="slide-2947-layer-7"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-237','-217',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['-60','-60','-60','-60']"
                   data-fontsize="['48','48','48','30']"
                   data-lineheight="['60','60','50','30']"
                   data-width="['710','710','none','none']"
                   data-height="none"
                   data-whitespace="wrap"
                   data-type="text"
                   data-responsive_offset="on"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]"
                   ><span className="slider-title">{content.length!==0?content[3].title:''} <br/></span></div>
             
               <div className="tp-caption tp-resizeme slider-sub-heading-wrap"
                   id="slide-2947-layer-10"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-237','-217',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['40','40','40','20']"
                   data-fontsize="['15','15','15','15']"
                   data-lineheight="['24','24','24','24']"
                   data-width="['710','710','510','300']"
                   data-height="none"
                   data-whitespace="wrap"
                   data-type="text"
                   data-responsive_offset="on"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1250,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]">
                   <span className="slider-sub-heading">{content.length!==0?content[3].caption:''} </span></div>
             

              
               <div className="tp-caption  tp-resizeme slider-button"
                   id="slide-2947-layer-8"
                   data-x="['middle','middle','middle','middle']"
                   data-hoffset="['-225','-147',0,'0']"
                   data-y="['middle','middle','middle','middle']"
                   data-voffset="['135','125','115','115']"
                   data-width="['710','710','none','none']"
                   data-height="['48','48','48','48']"
                   data-whitespace="nowrap"
                   data-type="text"
                   data-actions='[{"event":"click","action":"jumptoslide","slide":"rs-2947","delay":""}]'
                   data-responsive_offset="on"
                   data-responsive="off"
                   data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;"}]'
                   data-text-align="['left','left','center','center']"
                   data-paddingtop="[0,0,0,0]"
                   data-paddingright="[0,0,0,0]"
                   data-paddingbottom="[0,0,0,0]"
                   data-paddingleft="[0,0,0,0]" >
                    {content.length!==0?content[3].action==='readMore'?
                       <a href={content[3].link} className="btn">Read More</a>:content[3].action==='donate'?<a href='/donation' className="btn blob">Donate Now</a>:'':''}
               </div>
           </li> 

         </ul>
         <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}></div>	</div>
     </div> 
      
      
  }

export default React.memo(Slider)