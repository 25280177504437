import React from 'react';
import { ServerImagesUrl } from './include';

export const Navbar =()=> <div className="main-menu-area main-menu-area--absolute">
<div className="container">


    <div className="menu-logo">
        <div className="logo" style={{height:'100px', width:'140px'}}>
            <a href="/" className="logo-index">
                <img src={ServerImagesUrl+'/logo.png'} alt="small" /></a>
        </div>
        
        <nav id="easy-menu">
            <ul className="menu-list">

            <li><a href="/">Home</a></li>
               
                <li><a href="#!">About Us <i className="fa fa-angle-down"></i></a>
                    <ul className="dropdown">
                        <li><a href="/about">About Genius Skills</a></li>
                        <li><a href="/trustees">Board of Trustees</a></li>
                        <li><a href="/management">Staff & Volunteers</a></li>

                        <li><a href="/donors">Our Donors</a></li>
                    </ul>
                </li>

               <li><a href="#!">Media Center <i className="fa fa-angle-down"></i></a>
                    <ul className="dropdown">
                        <li><a href="/gallery">Gallery</a></li>             
                        <li><a href="/audios">Audio News</a></li>
                        <li><a href="/videos">Videos</a></li>
                    </ul>
                </li>

                <li><a href="#!">Resources<i className="fa fa-angle-down"></i></a>
                    <ul className="dropdown">
                    <li><a href="/news">News</a></li>
                        <li><a href="/reports">Reports</a></li>
                        <li><a href="/career">Career</a></li>
                        <li><a href="/registration">Skill Acquisation</a></li>
                       
                    </ul>
                </li>
                <li><a href="/events">Events</a></li>
                <li><a href="/contact">Contact Us</a></li>
           </ul>
       </nav>


       <div className="donate-button-wrap">
           <a href="/donation" className="btn base-bg hidden-xs hidden-sm blob">DONATE NOW</a>
           <a href="#" className="hidden-lg hidden-md" id="humbarger-icon"><i className="fa fa-bars"></i> </a>
       </div>

    </div>
</div>
</div>
