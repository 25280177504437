import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config } from './include';

export const Header =()=>{

    const [settings, setSettings] = useState([])

    const fetchSettings =()=>{
      let url = ServerUrl+'/fetch_controller.php';
      const fd = new FormData();
          fd.append('tablename', 'tbl_settings')
      axios.post(url, fd, config).then(result=>{
          if(Array.isArray(result.data)){

            setSettings(result.data[0])
          }
      }
      )
  }


  useEffect(()=>{
    fetchSettings()
 },[]);

return <header className="fund-header">
<div className="top-bar base-bg">
    <div className="container">
        <div className="row">
            <div className="col-sm-8 col-xs-8 text-xs-center">
                <div className="top-bar__contact">
                    <i className="fa fa-envelope"></i> <span className="pdl5">{settings&&settings.email}</span>
                    <span className="pdl15 pdr15">|</span>
                    <i className="fa fa-phone-square"></i> <span className="pdl5">+234 7053 7000 07</span>
                    <span className="pdl15 pdr15">|</span>
                    <i className="fa fa-phone-square"></i> <span className="pdl5">+234 8091 8999 96</span>
                </div>
            </div>
            <div className="col-sm-4 col-xs-4 text-right text-xs-center">
                <div className="social-icons ">
                    <span>Follow Us On : </span>
                    <ul className="list-inline pdl20">
                        <li><a href={settings&&settings.facebook} target="_blank"><i className="fa fa-facebook"></i> </a> </li>
                        <li><a href={settings&&settings.twitter} target="_blank"><i className="fa fa-twitter"></i> </a> </li>
                        <li><a href={settings&&settings.instagram} target="_blank"><i className="fa fa-instagram"></i> </a> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</header>}