import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './include';
import { getTime } from './globalFunction';

const Events =()=> {

    const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "select imageUrl, title, startTime, caption, description, fromDate, toDate, location, code, ID from tbl_events order by ID DESC limit 6"
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  useEffect(()=>{
    fetchContent()
 },[]);

return   <section className="upcomming-event-section section-padding">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="section-heading">
                    <h2 className="section-title section-title--small wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".1s">UPCOMING  <span className="base-color">EVENTS</span></h2>
                    <div className="section-heading-separator section-heading-separator--left-align wow fadeInUpXsd" data-wow-duration=".9s" data-wow-delay=".1s"></div>
                </div>
                <div className="upcommig-event-carousel wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".3s">


                {content.length!==0?content.map((item, id)=>   <div key={id} className="upcomming-event">
                        <div className="upcomming-event__image-wrap">
                            <img src={ServerImagesUrl+'/events/'+item.imageUrl} className="upcomming-event__image" alt="event" />
                            <div className="upcomming-event__date">
                                <i className="fa fa-clock-o"></i>
                                <span>{getTime(item.startTime)}</span>
                            </div>
                        </div>
                        <div className="upcomming-event__text-content">
                            <h4 className="upcomming-event__title"><a href={"/event_details/"+item.ID}>{item.title}</a></h4>
                            <div className="upcomming-event__meta-info">
                                <span className="upcomming-event__time"><i className="fa fa-calendar base-color"></i>{item.fromDate +"- "+item.toDate}  </span>
                                <span className="upcomming-event__place"><i className="fa fa-map-marker base-color"></i>{item.location}</span>
                            </div>
                            <p >{item.caption}</p>
                        </div>
                    </div>):''}

                    

                </div>
            </div>
           
        </div>
    </div>
</section>}



export default Events;