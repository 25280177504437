import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './_component/include';
import Template from './_component/template';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

const GalleryDetails =()=> {
  
    const [content, setContent] = useState([])
let params = useParams()
    const fetchContent =()=>{
        var sql = "select caption, imageList from tbl_gallery where status='true' and ID ="+params.code
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  const fetchCount =()=>{
    var sql = "update tbl_gallery set viewCount = viewCount+1  where  ID ="+params.code
  let url = ServerUrl+'/fetchBySql_controller.php';
  const fd = new FormData();
      fd.append('sql', sql)
  axios.post(url, fd, config)
}

  useEffect(()=>{
    fetchContent()
    fetchCount()
 },[]);


  return (<Template title='Gallery'>

<Helmet>
          <title> Genius Skills Empowerment Initiative </title>
         <meta name="description" content={content.length!==0? content[0].caption:''}  />
        <meta property="og:image" content={content.length!==0? ServerImagesUrl+'/gallery/'+content[0].imageUrl:''} />  
    </Helmet>

  <section className="blog-section section-padding ash-white-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading text-center">
                        <h2 className="section-title wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.7s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>  <span className="base-color"></span></h2>


                        <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}> {content.length!==0? content[0].caption:''}</span>

                        <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}></div>
                    </div>
                </div>
            </div>
            <div className="row row-eq-rs-height">
               
               
            {content.length!==0? JSON.parse(content[0].imageList).map((item, id)=>    <div key={id} className="col-md-4 col-sm-6 col-xs-12">
                    <div className="blog-post wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                      <a href={ServerImagesUrl+'/gallery/'+item.imageUrl} target='_blank'>  <div className="blog-post__thumbnail-wrap">
                            <img src={ServerImagesUrl+'/gallery/'+item.imageUrl} alt={id} style={{width:'400px', height:'300px'}} />
                            
                        </div>
                      </a>
                    </div>
                </div>):''}

            </div>
        </div>
    </section>


</Template>
  );
}

export default GalleryDetails;
