
import { ngoImagesUrl } from './_component/include';
import Template from './_component/template';

const Career =()=> {
  
  return (<Template title='Career'>
  

  <section className="section-padding ash-white-bg">
        <div className="container">
         
            <div className="row ">
  <div className="col-md-12 ">
  <h2> We are not currently recruiting, please check back later </h2>
                </div>

                    </div>
                </div>
                </section>


</Template>
  );
}

export default Career;
