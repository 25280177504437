import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl, ngoImagesUrl } from './include';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

 const Sliders =()=>{
    const [contents, setContents] = useState([])


    const fetchContent =()=>{
        var sql = "Select imageUrl, caption from tbl_slider order by rand() limit 4"
        let url = ServerUrl+'/fetchBySql_controller.php';
        const fd = new FormData();
            fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>{
          if(Array.isArray(result.data)){
            setContents(result.data)
          }
      }
      )
  }

  useEffect(()=>{
    fetchContent()
 },[]);


      return <>
      {contents.length >0 ?<Carousel infiniteLoop  showThumbs={false} autoPlay stopOnHover interval={5000}>
      {contents.map((data, id)=>
              <div  key={id} >
                  <img className="car" style={{width:'1500px', height:'700px'}} src={ServerImagesUrl+"/slides/"+data.imageUrl} alt={id}  />
                  {data.caption.length!==0?<p className="legend">{data.caption}</p>:''}
              </div>
              )}
          </Carousel>  :''}
      
  </>
  }

export default React.memo(Sliders)