import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './include';

const Targets =()=> {

    const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "select imageUrl, title from tbl_target "
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  useEffect(()=>{
    fetchContent()
 },[]);

return   <section className="gallery-section section-padding all-text-white">
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-12">
                <div className="section-heading text-center">
                    <h2 className="section-title wow fadeInUpXsd yelloq" data-wow-duration=".7s" data-wow-delay=".1s">OUR <span className="base-color">TARGETS</span></h2>
                    <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s">Helpless Youth, NYSC members, Schools, Religious Body, Women, Readily available individuals/groups etc</span>
                    <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s"></div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="text-center pdb35 wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".3s">
                    <ul className="list-inline filter-options">
                        <li><a href="#!" className="filter-options__item active filter btn" data-filter=".all">Show All</a></li>
                        <li ><a href="#!" id='youthMe' className="filter-options__item filter btn" data-filter=".Youth">Youth</a></li>
                        <li><a href="#!" className="filter-options__item filter btn" data-filter=".Women">Women</a></li>
                        <li><a href="#!" className="filter-options__item filter btn" data-filter=".Groups">Groups</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="row wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".3s">
            <div className="col-md-12">
                <div className="row row-eq-height" id="mixitup-grid">
                    
                {content.length!==0?content.map((item, id)=>    <div key={id} className={"col-lg-3 col-md-4 col-sm-6 col-xs-6 mix all natureal "+item.title}>
                        <a className="gallery-item  wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay={'.'+id+'s'} href={ServerImagesUrl+'/target/'+item.imageUrl} >
                            <img  src={ServerImagesUrl+'/target/'+item.imageUrl} className="gallery-item__photo" alt="portfolio" />
                        </a>
                    </div>):''}

                   
                </div>
            </div>
        </div>
    </div>
</section>}



export default Targets;