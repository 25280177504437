import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './include';
import { Header } from './header';
import { MobileNav } from './mobileNav';
import { Navbar } from './nav';
import  Footer  from './footer';

import {Helmet} from "react-helmet";
import { Adverts } from './adverts';

const Template=(props)=> {
    const [content, setContent] = useState({
        keywords:'',
        title:'',
        description:'',
        imageUrl:''
    })

    const fetchContent =()=>{
        var sql = "select description, keywords,  (select imageUrl from tbl_slider order by rand() limit 1 ) as imageUrl, title from tbl_settings"
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data[0]))
  }

  useEffect(()=>{
    fetchContent()
 },[]);
  return (<>
     {/* <div className="preloader">
      <div className="loader-inner ball-scale-multiple">
          <div></div>
          <div></div>
          <div></div>
      </div>
  </div> */}
    <Header />
   <MobileNav />

    <div className="slider-area">
        <Navbar />

        <div className="banner-area all-text-white text-center" style={{backgroundImage:`url(${ServerImagesUrl+"/slides/"+content.imageUrl})`}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="page-title">{props.title.toUpperCase()}</h1>
                    <ul className="fund-breadcumb">
                        <li><a href="/">Home</a> </li>
                        <li><a href={window.location.pathname}>{props.title}</a> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    </div>

   <div className="body-overlay"></div>

{props.children}



<Adverts />
<Footer />
        
</>
  );
}

export default Template;
