
import { ngoImagesUrl } from './_component/include';
import Template from './_component/template';

const StaffDetails =()=> {
  
  return (<Template title='MRS. GRACE ACHI'
  description ='Genius Skills Empowerment Initiative is dedicated to enriching the lives of the helpless and vulnerable in the society through the provision of skill acquisition and empowerment training programs, hereby alleviating the biting economic hardship currently being experienced in our nation.'
  keyword ='GenSkills Contact, Genius Skills Office Address, +234 7053 7000 07, +234 8091 8999 96'
  imageUrl={ngoImagesUrl+'/slider/1.JPG'}>
  




  <section className="section-padding ash-white-bg">
        <div className="container">
         
            <div className="row ">
  <div className="col-md-12 ">
                    <div className="blog-post wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}>
                        <div className="blog-post__thumbnail-wrap">
                            <img src="images/blog/blog-1.jpg" alt="blog" />
                            
                        </div>
                        <div className="blog-post__text-content">
                            <h4 className="blog-post__title heading-label-four"><a href="#">EDUCATION FOR CHILDREN</a></h4>
                            <div className="blog-post__meta-info">
                                <span className="small-text"><i className="fa fa-user base-color"></i>Admin</span>
                                <span className="small-text"><i className="fa fa-calendar base-color"></i>25 January</span>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adip it scing elit, sed do eiusmod tempor incididu duut ut labore et dolore </p>
                        </div>
                    </div>




                    <div className="fund-event">
                            <div className="fund-event__date">
                                <span className="fund-event__day base-color">17</span>
                                <span className="small-text nv-color">Feb - 2018</span>
                            </div>
                            <div className="fund-event__main">
                                <div className="fund-event__image-content">
                                    <div className="fund-event__image-wrap">
                                        <img src="images/events/1.jpg" className="fund-event__image" alt="" />
                                    </div>
                                    <div className="fund-event__text-content">
                                        <h4 className="fund-event__title"><a href="#">GET VOLUNTEER IDEA FESTIVAL 2017</a></h4>
                                        <div className="fund-event__time">
                                            <i className="fa fa-calendar fund-event__icon base-color"></i>Feb 11, 2018 08:00 AM to Jun 25, 2018 12:00 PM
                                        </div>
                                        <div className="fund-event__address">
                                            <i className="fa fa-map-marker fund-event__icon base-color"></i>South Africa
                                        </div>
                                        <div className="fund-event__ticket-price">
                                            <i className="fa fa-ticket fund-event__icon base-color"></i>$80
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt at the labore et dolore magna aliqua. Ut enim ad minim veniam sequat.</p>
                            </div>
                        </div>
                </div>

                    </div>
                </div>
                </section>


</Template>
  );
}

export default StaffDetails;
