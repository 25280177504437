import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl} from './include';

const Activity =()=> {

    const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "select title, message, imageUrl, poster, datePosted from tbl_news where status ='true' order by ID DESC limit 6 "
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  useEffect(()=>{
    fetchContent()
 },[]);

return  <section className="section-padding">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="section-heading text-center">
                    <h2 className="section-title wow fadeInUpXsd yellow" data-wow-duration=".7s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.7s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>Our RECENT <span className="base-color">ACTIVITIES</span> </h2>

                    <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>Synopsis of our empowerment, visitations and training programs </span>

                    <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1.1s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}></div>
                </div>
            </div>
        </div>





        <div className="row row-eq-rs-height">
            
            
        {content.length!==0?content.map((item, id)=>  
            <div key={id} className="col-md-4 col-sm-6col-xs-12">
                <div className="blog-post wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s">
                    <div className="blog-post__thumbnail-wrap">
                        <img src={ServerImagesUrl+'/news/'+item.imageUrl} alt="" />
                        <div className="blog-post__like-comment">
                            <span><i className="fa fa-comments"></i>22 Comment</span>
                            <span>|</span>
                            <span><i className="fa fa-thumbs-up"></i>105 Likes</span>
                        </div>
                    </div>
                    <div className="blog-post__text-content ">
                        <h4 className="blog-post__title heading-label-four"><a href="#">{item.title}</a></h4>
                        <div className="blog-post__meta-info">
                            <span className="small-text"><i className="fa fa-user base-color"></i>{item.poster}</span>
                            <span className="small-text"><i className="fa fa-calendar base-color"></i>{item.datePosted}</span>
                        </div>
                        <p dangerouslySetInnerHTML={{__html:item.message.slice(0,200)}}></p>
                        <a href="#" className="btn ">Read More</a>
                    </div>
                </div>
            </div>):''}


        </div>


{/* 
        <div className="row row-eq-rs-height">
           
        {content.length!==0?content.map((item, id)=>  
            <div key={id} className="col-md-4 col-sm-6 col-xs-12">  
                <div className="our-causes wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}>
                    <div className="our-causes__image-wrap">
                        <img src={ServerImagesUrl+'/gallery/'+item.cover} className="our-causes__image" alt="" />
                        <div className="our-causes__percentage base-bg">
                            <div className="our-causes__rised">
                                RAISED - <span className="base-color"> $80,500</span>
                            </div>
                            <div className="our-causes__goal">
                                <span className="nv-color">GOAL - </span> <span> $1,40,000 </span>
                            </div>
                        </div>
                    </div>
                    <div className="our-causes__text-content text-center">
                        <div className="progress-item">
                            <div className="progress-bg">
                                <div id="progress-one" className="progress-rate base-bg" data-value="80" >
                                </div>
                            </div>
                        </div>
                        <h4 className="text-uppercase our-causes__title"><a href="#">Raise fund for Healthy Food</a></h4>
                        <p>{item.caption}</p>
                        <a href="#" className="btn">Read More</a>
                    </div>
                </div>
            </div>):''}


        </div> */}
    </div>
</section>}



export default Activity;