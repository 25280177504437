import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config } from './_component/include';
import Template from './_component/template';

const Contact =()=> {
  
    const [settings, setSettings] = useState([])

    const fetchSettings =()=>{
      let url = ServerUrl+'/fetch_controller.php';
      const fd = new FormData();
          fd.append('tablename', 'tbl_settings')
      axios.post(url, fd, config).then(result=>{
          if(Array.isArray(result.data)){

            setSettings(result.data[0])
          }
      }
      )
  }
  
const [notice, setNotice] = useState({
    isLoading : false
});
const [isSubmitting, setIsSubmitting] = useState(false); 
const [errors, setErrors] = useState({});

let initials = {
    sendName:'',
    email:'',
    subject:'',
    messageBody:'',
    puzzle:''
}
const [message, setMessage] = useState(initials)

const generatePuzzle =()=>{
        var num1 = Math.floor(Math.random() * 9)
        var num2 = Math.floor(Math.random() * 9)
        var answer = num1+num2
        setMessage({...initials, num1:num1, num2:num2, answer:answer})
    
} 
const handleChange = event =>{
    const {name, value} = event.target
   setMessage({...message, [name] : value });
   setErrors({...errors, [name]:''});
}

const handleSubmit = event =>{
    event.preventDefault();  
    setErrors(ValidateInput(message));
        setIsSubmitting(true);
} 
const ValidateInput =(values)=>{
    let errors = {};   
    let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
    
    if(!values.sendName){
        errors.sendName ='Please enter your Name';
    }
    if(!email){
        errors.email ='Please enter valid email address';
    }

    if(!values.subject){
        errors.subject ='Please enter subject title';
    }
    if(!values.messageBody){
        errors.messageBody ='Please enter your message';
    }
    if(!values.puzzle){
        errors.puzzle ='Please provide answer';
    }else if(Number(values.puzzle) !== Number(values.answer)){
        errors.puzzle ='Answer provided to puzzle is not correct';
    }
    return errors;
    }

useEffect(()=>{
    
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors, isSubmitting])

    function submit(){        
        setNotice({...notice,  isLoading: true})     
         
        const fd = new FormData();
        for (let k in message){
            fd.append(k, message[k])
        } 
        
        let url = ServerUrl+'/save_controller.php?tablename=tbl_contact';
        axios.post(url, fd, config)
        .then(response =>{       
            if (response.data.type ==='success'){ 
                alert(response.data.message)             
             
          generatePuzzle();
            }else{
                alert(response.data.message)  
                }       
        })
        .catch((error)=>{
            alert(error.message)  
            
        }).finally(()=>{
            setNotice({...notice,  isLoading: false  }) 
            setIsSubmitting(false);
            setMessage(initials)
        })   
    }


    useEffect(()=>{
        generatePuzzle();
        fetchSettings();
        }, [])

  return (<Template title='Contact Us'>
  

   <section className="section-padding">
        <div className="col-md-12">
            <div className="section-heading text-center">
                <h2 className="section-title">Get in <span className="base-color">touch</span> </h2>
                <span className="section-sub-title">Need Help ? Write Us</span>
                <div className="section-heading-separator"></div>
            </div>
        </div>
        <div id="mapContainer">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.0354081348905!2d7.319893814571642!3d9.151274789582116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104dd8e50790d20b%3A0x5e8db41804193390!2sSultan%20Dasuki%20Rd%2C%20Kubwa%2C%20Abuja!5e0!3m2!1sen!2sng!4v1615837383627!5m2!1sen!2sng" width="1250" height="450"  allowfullscreen="" loading="lazy"></iframe>
        </div>


        <div className="container no-padding">
            <div className="main-contact">
                <form className="contact-form" id="contact">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="input-group">
                               
                                <input type="text" placeholder="Your Name" name="sendName" value={message.sendName}   onChange={handleChange} className={errors.sendName  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.sendName && errors.sendName}</span>	
				
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-group">
                            <input type="email"  placeholder="Your Email" name="email" value={message.email}   onChange={handleChange} className={errors.email  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.email && errors.email}</span>	
                    
                            </div>
                        </div>
                    </div>
                    <div className="input-group">
                    <input type="text"  placeholder="Subject" name="subject" value={message.subject}   onChange={handleChange} className={errors.subject  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.subject && errors.subject}</span>	
				
                    </div>
                    <div className="input-group">
                        

                        <textarea name="messageBody" value={message.messageBody} onChange={handleChange} className={errors.messageBody ? 'input-group__textarea form-control formerror' : 'input-group__textarea form-control'} rows="8" cols='80' placeholder="Message">{message.messageBody}</textarea>
                    <span className="spanerror">{errors.messageBody&& errors.messageBody}</span>

                    </div>
                    <div className="input-group">
                    <input type="text" name="puzzle" value={message.puzzle} placeholder={message.num1 + ' + '+ message.num2 + '  =?' } onChange={handleChange} className={errors.puzzle ? 'input-group__textarea form-control formerror' : 'input-group__textarea form-control'} /> 
        <span className="spanerror">{errors.puzzle && errors.puzzle}</span>	
				
                    </div>
                    {notice.isLoading?<button type="button" disabled={true}  className="btn base-bg">please wait... <i className="fa fa-spin fa-refresh" ></i></button>:
				<button  onClick={handleSubmit} className="btn base-bg">Submit </button>}

                    <span className="text-mute pdl15">* All fields are mandatory</span>
                </form>
                <div className="contact-address">
                    <div className="contact-address-item">
                        <span className="icon-wrap"><i className="fa fa-map-marker contact-icon"></i></span>
                        <div className="text-content">
                            <div className="yellow contact-title">Office Location</div>
                            {settings&&settings.address}
                        </div>
                    </div>
                    <div className="contact-address-item">
                        <span className="icon-wrap"><i className="fa fa-phone contact-icon"></i></span>
                        <div className="text-content">
                            <div className="yellow contact-title">Contact Number</div>
                            {settings&&settings.telephone} - {settings&&settings.whatsapp}
                        </div>
                    </div>
                    <div className="contact-address-item">
                        <span className="icon-wrap"><i className="fa fa-envelope contact-icon"></i></span>
                        <div className="text-content">
                            <div className="yellow contact-title">Contact Mail</div>
                            {settings&&settings.email}<br />
                           
                        </div>
                    </div>

                    <div className="contact-address-item">
                        <span className="icon-wrap"><i className="fa fa-map-marker contact-icon"></i></span>
                        <div className="text-content">
                            <div className="yellow contact-title">Website</div>
                            {settings&&settings.website}<br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</Template>
  );
}

export default Contact;
