
import { ngoImagesUrl } from './_component/include';
import Template from './_component/template';
import { Testimonials } from './_component/testimonial';

const About =()=> {
  
  return (<Template title='About Us' >
  
  <section className="section ">
      <div className="container">
        <div className="row">
         
          <div className="col-sm-1"></div>


          <div className="col-md-5">
                    <div className="fund-volunteer text-center">
                        
                        <div className="fund-volunteer__text-content" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>
                            <h3 className="fund-volunteer__name">OUR VISSION</h3>
                            <div className="section-heading-separator section-heading-separator--small"></div>
                            <p>Empowering people through free skill acquisition as a tool for a meaningful and sustainable living.</p>
                           
                        </div>
                    </div>
                </div>

          <div className="col-sm-5 ">
          <div className="fund-volunteer text-center">
                        
                        <div className="fund-volunteer__text-content" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>
                            <h3 className="fund-volunteer__name">OUR MISSION</h3>
                            <div className="section-heading-separator section-heading-separator--small"></div>
                            <p>We are committed to helping the less
                                privileged in our society by creating
                                an enabling environment to bring
                                to fore their creativity and talents
                                through free skill acquisition
                                and empowerment.</p>
                           
                        </div>
                    </div>
          </div>
        </div>
      </div>
    </section>





    <section className="section-padding ash-white-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading text-center" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                        <h2 className="section-title yellow">OUR  <span className="base-color">CORE VALUES</span> </h2>
                        <span className="section-sub-title">We are a volunteer-based organization, funded largely by donations, contribution by members and participants.</span>
                        <div className="section-heading-separator"></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 "  data-wow-duration="1.5s" data-wow-delay=".6s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                
                           <img alt='core value' src={ngoImagesUrl+"/core.png"} />
                            </div>
               

                <div className="col-md-6">
                    <div className="about-us-text-content mb30"  data-wow-duration="1.5s" data-wow-delay=".6s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                        <h4>GENIUS SKILLS EMPOWERNMENT INITIATIVES</h4>
                    <p style={{fontSize:'22px'}}>Our organization is dedicated to enriching the lives of the helpless and
                        vulnerable in the society through the provision  of skill acquisition and empowerment training
                        programs, hereby alleviating the biting
                        economic hardship currently being
                        experienced in our nation as our own contribution to our society. We provide a platform for comprehensive empowernment of individuals/groups and encourage ownership of their own development thereby reducing poverty.</p>

                    </div>
                </div>
            </div>
        </div>
    </section>




    <section className="section-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="blog-details">
                 
                        <div className="images-box-wrap" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                            
                            <div className="left-images">
                                <img src={ngoImagesUrl+"/slider/1.png"} className="mb30 img-round" alt="" style={{height:'200px', width:'400px'}} />
                                <img src={ngoImagesUrl+"/slider/2.png"} className="mb30 img-round" alt="" style={{height:'200px', width:'400px'}} />
                            </div>

                            <div className="right-images">
                                <img src={ngoImagesUrl+"/slider/3.JPG"} className="mb30 img-round" alt="" style={{height:'400px'}} />
                            </div>

                        </div>
                        <p style={{fontSize:'20px'}}>We believe that skills acquisition can better the lives of our trainees, and we are poised to achieving this feat with the unflinching support of our partnering gems and amiable organizations like yours. Coming from a background of short-term trainings of individual/groups; experience has shown that short term trainings could not help as much as intended thereby positioning the trainees in a situation that compelled them to dispose off their empowernment materials (sewing machings, other working tools), because of inadequate pre-requisite and indepth training before empowernment, so this was what birthed the vision of Genius Skills Empowerment Initiative.</p>
                    
                      
                        </div> 
                       
                    </div>
                </div>
</div>
</section>
    <section className="counter-section all-text-white section-padding" style={{backgroundImage:`url(${ngoImagesUrl+"/slider/4.JPG"})`}}>
        <div className="container">
            <div className="row pdt15">
              
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="counter-item">
                        <div className="counter-item__icon-wrap">
                            <img src="images/about/icon-hand.png" className="counter-item__icon" alt="" />
                            <span className="counter-item__label">Available Skills</span>
                        </div>
                        <div className="counter-item__count-wrap">
                            <span className="counter-item__count">200</span>+
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="counter-item">
                        <div className="counter-item__icon-wrap">
                            <img src="images/about/icon-man.png" className="counter-item__icon" alt="" />
                            <span className="counter-item__label">People Trained</span>
                        </div>
                        <div className="counter-item__count-wrap">
                            <span className="counter-item__count">5000</span>+
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="counter-item">
                        <div className="counter-item__icon-wrap">
                            <img src="images/about/icon-country.png" className="counter-item__icon" alt="" />
                            <span className="counter-item__label">Communities Reached</span>
                        </div>
                        <div className="counter-item__count-wrap">
                            <span className="counter-item__count">200</span>+
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<Testimonials />
</Template>
  );
}

export default About;
