import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config } from './_component/include';
import Template from './_component/template';

const Videos =()=> {
  
    const [content, setContent] = useState([])
    const fetchContent =()=>{
        var sql = "select link from tbl_youtube  order by ID DESC"
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  useEffect(()=>{
    fetchContent()
 },[]);


  return (<Template title='Videos'>


  <section className="blog-section section-padding ash-white-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading text-center">
                        <h2 className="section-title wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.7s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}> Watch our <span className="base-color">Events</span></h2>


                        <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}> {content.length!==0? content[0].caption:''}</span>

                        <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}></div>
                    </div>
                </div>
            </div>
            <div className="row row-eq-rs-height">
               
               
            {content.length!==0? content.map((item, id)=>    <div key={id} className=" col-sm-6 col-xs-12">
                    <div className="blog-post wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
 
                      <div className="blog-post__thumbnail-wrap">
                            
                      <iframe width="560" height="315" src={item.link} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            
                        </div>
                    </div>
                </div>):''}

            </div>
        </div>
    </section>


</Template>
  );
}

export default Videos;
