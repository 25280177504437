import {ServerImagesUrl } from "./include"


export const MobileNav =()=> <nav className="mobile-background-nav">
<div className="mobile-inner">
    <span className="mobile-menu-close"><i className="icon-icomooon-close"></i></span>
    <ul className="menu-accordion">
    <div className="logo" style={{height:'100px', width:'100px'}}>
            <a href="/" className="logo-index">
                <img src={ServerImagesUrl+'/logo.jpg'} alt="small" /></a>
        </div>
    <li><a href="/">Home</a></li>
        <li><a href="#!" className="has-submenu">About Us <i className="fa fa-angle-down"></i></a>
                    <ul className="dropdown">
                    <li><a href="/about">About Genius Skills</a></li>
                        <li><a href="/trustees">Board of Trustees</a></li>
                        <li><a href="/management">Staff & Volunteers</a></li>

                        <li><a href="/donors">Our Donors</a></li>
                    </ul>
                </li>

                <li><a href="#!" className="has-submenu">Media Center <i className="fa fa-angle-down"></i></a>
            <ul className="dropdown">
            <li><a href="/gallery">Gallery</a></li>             
                        <li><a href="/audios">Audio News</a></li>
                        <li><a href="/videos">Videos</a></li>
            </ul>
        </li>


                <li><a href="#!" className="has-submenu">Resources<i className="fa fa-angle-down"></i></a>
                    <ul className="dropdown">
                    <li><a href="/news">News</a></li>
                        <li><a href="/reports">Reports</a></li>
                        <li><a href="/career">Career</a></li>
                        <li><a href="/registration">Skill Acquisation</a></li>
                    </ul>
                </li>

                <li><a href="/events">Events</a></li>
                <li><a href="/contact">Contact Us</a></li>
        <li ><a href="/donation" > <span className='blob'> Donate Now </span></a></li>


   </ul>
</div>
</nav>