import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl, Currency } from './_component/include';
import Template from './_component/template';
import { FormatNumber, longDate } from './_component/globalFunction';

const Donors =()=> {
  
  const [content, setContent] = useState([])

  const fetchContent =()=>{
      var sql = "select fullname, address, amount, dateDonate from tbl_donation where status='confirm' and anonymous ='No' order by ID DESC"
    let url = ServerUrl+'/fetchBySql_controller.php';
    const fd = new FormData();
        fd.append('sql', sql)
    axios.post(url, fd, config).then(result=>setContent(result.data))
}

useEffect(()=>{
  fetchContent()
},[]);

  return (<Template title='Donors'>
  

  <section className="section-padding ash-white-bg">
        <div className="container">
        <h4 className="donate-page-title text-uppercase">Donors Details </h4>
            <div className="row ">
  <div className="col-md-12 ">
                                    
                                    
                                <table className="table table-responsive ">
  <thead>
    <th> <h4>SN</h4> </th>
    <th> <h4>Name </h4></th>
    <th> <h4>Organization</h4> </th>
    <th> <h4>Amount ({Currency}) </h4></th>
    <th> <h4>Date </h4></th>
  </thead>
  <tbody>
   
   
  {content.length!==0?content.map((item, id)=>  <tr key={id}>
      <td>{id+1}</td>
      <td>{item.fullname}</td>
      <td>{item.address}</td>
      <td>{FormatNumber(item.amount)}</td>
      <td>{longDate(item.dateDonate)}</td>
    </tr>):''}

  </tbody>
                   </table>



                 
                </div>

                    </div>
                </div>
                </section>


</Template>
  );
}

export default Donors;
