import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './_component/include';
import Template from './_component/template';

const Registration =()=> {
  
const [notice, setNotice] = useState({
    isLoading : false
});
const [isSubmitting, setIsSubmitting] = useState(false); 
const [errors, setErrors] = useState({});

let initials = {
    program:'',
    duration:'',
    session:'',
    fullname:'',
    email:'',
    birthDate:'',
    address:'',
    gender:'',
    telephone:'',
    puzzle:''
}
const [user, setUser] = useState(initials)
const [image, setImage] = useState('');
const generatePuzzle =()=>{
        var num1 = Math.floor(Math.random() * 9)
        var num2 = Math.floor(Math.random() * 9)
        var answer = num1+num2
        setUser({...initials, num1:num1, num2:num2, answer:answer})
    
} 
const handleChange = event =>{
    const {name, value} = event.target
   setUser({...user, [name] : value });
   setErrors({...errors, [name]:''});
}

const handleSubmit = event =>{
    event.preventDefault();  
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
} 
const ValidateInput =(values)=>{
    let errors = {};   
    let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
    
    if(!values.fullname){
        errors.fullname ='Please enter your Name';
    }
    if(!values.program){
        errors.program ='Please select program';
    }
    if(!values.session){
        errors.session ='Please select session';
    }
    if(!values.duration){
        errors.duration ='Please select duration';
    }
    if(!values.gender){
        errors.gender ='Please select gender';
    }
    if(!email){
        errors.email ='Please enter valid email address';
    }

    if(!values.telephone){
        errors.telephone ='Please enter telephone';
    }
    if(!values.address){
        errors.address ='Please enter your address';
    }
    if(!values.puzzle){
        errors.puzzle ='Please provide answer';
    }else if(Number(values.puzzle) !== Number(values.answer)){
        errors.puzzle ='Answer provided to puzzle is not correct';
    }
    return errors;
    }

useEffect(()=>{
    
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors, isSubmitting])

    function submit(){        
        setNotice({...notice,  isLoading: true})     
         
        const fd = new FormData();
        for (let k in user){
            fd.append(k, user[k])
        } 
        fd.append("image", image);
        let url = ServerUrl+'/save_controller.php?tablename=tbl_trainees';
        axios.post(url, fd, config)
        .then(response =>{    
             if (response.data.type ==='success'){ 
                alert(response.data.message)             
             
          generatePuzzle();
            }else{
                alert(response.data.message)  
                }       
        })
        .catch((error)=>{
            alert(error.message)  
            
        }).finally(()=>{
            setNotice({...notice,  isLoading: false  }) 
            document.getElementById('passport').value=''
            setImage('')
            setIsSubmitting(false);
            setUser(initials)
        })   
    }


   const  handleFile = function(fieldName){
    return function(newValue){                 
        const value = newValue.target.files[0]
      // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
    let displayMessage = '';
      var fileName =  document.getElementById('passport').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile ==="jpg" || extFile ==="jpeg" || extFile ==="png" || extFile ==="gif" || extFile ==="JPG" || extFile ==="JPEG" || extFile ==="PNG" || extFile ==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1mb';
       document.getElementById('passport').value = '';
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
       
          const img = document.getElementById('viewPassport');
                     img.src = e.target.result             
              
          };
          reader.readAsDataURL(input.target.files[0]); 
          setImage(input.target.files[0])  
        }
    }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3mb'
       document.getElementById(input.target.name).value = '';
       setImage('');
    }   
    
    if(displayMessage.length > 0){
        alert(displayMessage)
      }
} 


    useEffect(()=>{
        generatePuzzle();
        }, [])
  return (<Template title='Registration'>
  

  <div className="main-content section-padding">
        <div className="container">
            <h4 className="donate-page-title text-uppercase">REGISTRATION FORM  </h4>
            <form className="donate-page-form" >

            <div className="row pdb30">
                    <div className="col-md-3 pdb10">
                        <span>Select one Program <span className="red-color">*</span> </span>
                    </div>
                    <div className="col-md-9">
                        <div className="input-group">
                            <select className="input-group__select hide-search--dropdown" name="program" value={user.program}   onChange={handleChange}>
                            <option value="">Select</option>
                                <option value="Fashion Design">Fashion Design</option>
                                <option value="Bead Making">Bead Making</option>
                                <option value="Confectionaries"> Confectionaries</option>
                                <option value="Hair Making">Hair Making</option>
                                <option value="Printing Business">Printing Business</option>
                                <option value="Automobile Venture">Automobile Venture</option>
                                <option value="Carpentry"> Carpentry</option>
                                <option value="Soap & Chemical Business">Soap & Chemical Business</option>

                                <option value="Photography & Video Editing">Photography & Video Editing</option>
                                <option value="Bee Keeping">Bee Keeping</option>
                                <option value="Glass & Aluminum Fabrication"> Glass & Aluminum Fabrication</option>
                                <option value="Electrical House Wiring">Electrical House Wiring</option>
                                <option value="Modern Painting Technology">Modern Painting Technology</option>
                                <option value="Plumbing">Plumbing</option>
                                <option value="POP Design"> POP Design</option>
                                <option value="Solar Power Installation & Maintenance">Solar Power Installation & Maintenance</option>

                                <option value="Shoe Making">Shoe Making</option>
                                <option value="Computer Technology & Programming">Computer Technology & Programming</option>
                                <option value="Arts & Crafts (Weaving, Tue and Dye, Carving etc)"> Arts & Crafts (Weaving, Tue and Dye, Carving etc) </option>
                                <option value="Fishery">Fishery</option>
                                <option value="Snailry">Snailry</option>

                            </select>
                            <span className="spanerror">{errors.program && errors.program}</span>
                        </div>
                       
                    </div>
                </div>

            <div className="row pdb30">
                    <div className="col-md-3 pdb10">
                        <span>Course Duration  <span className="red-color">*</span> </span>
                    </div>
                    <div className="col-md-9">
                        <div className="input-group">
                            <select className="input-group__select hide-search--dropdown" name="duration" value={user.duration}   onChange={handleChange}>
                            <option value="">Select</option>
                                <option value="Appreciation One-Week">Appreciation One-Week</option>
                                <option value="Beginners - 1 Month">Beginners - 1 Month</option>
                                <option value="Intermediate - 3 Month"> Intermediate - 3 Month</option>
                                <option value="Advance - 6 Month">Advance - 6 Month</option>
                            </select>
                            <span className="spanerror">{errors.duration && errors.duration}</span>
                        </div>
                       
                    </div>
                </div>

                <div className="row pdb30">
                    <div className="col-md-3 pdb10">
                        <span>Session  <span className="red-color">*</span> </span>
                    </div>
                    <div className="col-md-9">
                        <div className="input-group">
                            <select className="input-group__select hide-search--dropdown" name="session" value={user.session}   onChange={handleChange}>
                            <option value="">Select</option>
                                <option value="Morning">Morning</option>
                                <option value="Evening">Evening</option>
                                <option value="Weekday"> Weekday</option>
                                <option value="Weekend">Weekend</option>
                            </select>
                            <span className="spanerror">{errors.session && errors.session}</span>
                        </div>
                       
                    </div>
                </div>
                

                <div className="row pdt30">
                    <div className="col-md-12">
                        <h4 className="nv-color text-uppercase">Participant Details</h4>
                    </div>
                </div>
                <div className="row donate-detail-form">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                        <input type="text" placeholder="Fullname *" name="fullname" value={user.fullname}   onChange={handleChange} className={errors.fullname  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.fullname && errors.fullname}</span>	
				
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                            <input type="email" className="form-control input-group__input" placeholder="Email *" name="email" value={user.email}   onChange={handleChange} className={errors.email  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.email && errors.email}</span>	
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                            <input type="date" className="form-control input-group__input" placeholder="Birth Date *" name="birthDate" value={user.birthDate}   onChange={handleChange} className={errors.birthDate  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.birthDate && errors.birthDate}</span>	
                        </div>
                    </div>
                    <div className=" col-sm-12 col-xs-12">
                        <div className="input-group">
                            <input type="text" className="form-control input-group__input" placeholder="Contact Address" name="address" value={user.address}   onChange={handleChange} className={errors.address  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.address && errors.address}</span>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                            <select className="input-group__select hide-search--dropdown" name="gender" value={user.gender}   onChange={handleChange} >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female"> Female</option>
                            </select>
                            <span className="spanerror">{errors.gender && errors.gender}</span>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                            <input type="text" className="form-control input-group__input" placeholder="Telephone" name="telephone" value={user.telephone}   onChange={handleChange} className={errors.telephone  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.telephone && errors.telephone}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                    <input type="text" name="puzzle" value={user.puzzle} placeholder={user.num1 + ' + '+ user.num2 + '  =?' } onChange={handleChange} className={errors.puzzle ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> 
        <span className="spanerror">{errors.puzzle && errors.puzzle}</span>	
				
                    </div></div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="col-md-3">Passport:
                        </div>
                        <div className="input-group">
                        <input id="passport"  onChange={handleFile('passport')} className="form-control input-group__input" name="passport" type="file" accept="image/*" />
                           
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12">

                    <img id="viewPassport" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ServerImagesUrl+"/logo.png"}} src={image}  style={{display:'block',height:'100px',width:'100px'}} title="Logo"  alt="Logo" />  

                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-group">
                        {notice.isLoading?<button type="button" disabled={true}  className="btn ">please wait... <i className="fa fa-spin fa-refresh" ></i></button>:
				<button  onClick={handleSubmit} className="btn ">Submit Form </button>}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</Template>
  );
}

export default Registration;
