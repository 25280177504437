import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './_component/include';
import Template from './_component/template';

const Gallery =()=> {
  
    const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "select caption, cover, ID from tbl_gallery where status='true'"
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  useEffect(()=>{
    fetchContent()
 },[]);


  return (<Template title='Gallery'>
  




  <section className="blog-section section-padding ash-white-bg">
        <div className="container">
            <div className="row row-eq-rs-height">
               
               
            {content.length!==0?content.map((item, id)=>    <div key={id} className="col-md-4 col-sm-6 col-xs-12">
                    <div className="blog-post wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                      <a href={'/gallery_details/'+item.ID}>  <div className="blog-post__thumbnail-wrap">
                            <img src={ServerImagesUrl+'/gallery/'+item.cover} alt={id} style={{width:'400px', height:'300px'}} />
                            <div className="blog-post__like-comment">
                                <span><i className="fa fa-comments"></i>{item.caption}</span>
                            </div>
                        </div>
                      </a>
                    </div>
                </div>):''}

            </div>
        </div>
    </section>


</Template>
  );
}

export default Gallery;
