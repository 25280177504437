import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config } from './include';
import { ngoImagesUrl } from './include';
import { NewsLeter } from './news_letter';

const scrollTop =()=>{
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}
const Footer = ()=>{
    const [settings, setSettings] = useState([])

    const fetchSettings =()=>{
      let url = ServerUrl+'/fetch_controller.php';
      const fd = new FormData();
          fd.append('tablename', 'tbl_settings')
      axios.post(url, fd, config).then(result=>{
          if(Array.isArray(result.data)){

            setSettings(result.data[0])
          }
      }
      )
  }


  useEffect(()=>{
    fetchSettings()
 },[]);

    const [displayScroll, setDisplayScroll] = useState(false)
    window.addEventListener('scroll', function(event){
        if(this.scrollY>300){
            setDisplayScroll(true)
        }else{
            setDisplayScroll(false)
        }
    })
    return  <footer className="fund-footer ">
    <div className="main-footer all-text-white section-padding" style={{ backgroundImage: `url(${ngoImagesUrl+'/slider/1.JPG'})`}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="main-footer__top text-center pdb10">
                        <a href="#" className="footer-logo"><img style={{height:'100px'}} src={ngoImagesUrl+'/logo.jpg'} alt="logo" /></a>
                        <p>Genius Skills Empowerment Initiative is dedicated to
enriching the lives of the helpless and
vulnerable in the society through free skills acquisition and  business establishment thereby alleviating the biting economic hardship currently being
experienced in our nation as our own contribution to our society. </p>
                    </div>
                </div>
            </div>
            <div className="row row-eq-rs-height">
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="widget widget--footer widget--userfull-link">
                        <div className="widget__heading">
                            <h4 className="widget__title">QUICK LINKS</h4>
                        </div>
                        <div className="widget__text-content">
                            <ul>
                                <li> <a href="/career">Join our volunteer team.</a></li>
                                <li><a href="/news">Read our Latest News.</a></li>
                                <li><a href="/events">Check the upcoming Events.</a></li>
                                <li><a href="/donors">Check Our Donor List.</a></li>
                                <li><a href="/registration">Skill Acquisation Training</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="widget widget--footer widget--contact">
                        <div className="widget__heading">
                            <h4 className="widget__title">OUR CONTACT</h4>
                        </div>
                        <div className="widget__text-content">
                            <div className="contact-way">
                                <span className="base-bg contact-icon"><i className="fa fa-location-arrow"></i></span>
                                <span className="contact-text">{settings&&settings.address} </span>
                            </div>
                            <div className="contact-way">
                                <span className="base-bg contact-icon"><i className="fa fa-phone"></i></span>
                                <span className="contact-text">+234 7053 7000 07, +234 8091 8999 96</span>
                            </div>
                            <div className="contact-way">
                                <span className="base-bg contact-icon"><i className="fa fa-envelope-o"></i></span>
                                <span className="contact-text">{settings&&settings.email}</span>
                            </div>
                            <div className="social-icons pdt5">
                                <span>Follow Us On : </span>
                                <ul className="list-inline pdl20">
                                    <li><a href={settings&&settings.facebook} target="_blank"><i className="fa fa-facebook"></i> </a> </li>
                                    <li><a href={settings&&settings.twitter} target="_blank"><i className="fa fa-twitter"></i> </a> </li>
                                    <li><a href={settings&&settings.instagram} target="_blank"><i className="fa fa-instagram"></i> </a> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

               <NewsLeter />

            </div>
        </div>
    </div>
    <div className="copyright-area all-text-white pdt25 pdb25">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-sm-6">
                    <div className="copyright-text text-xs-center">
                        &copy; 2020 - {new Date().getFullYear()}. All Rights Reserved. Developed by <span className="base-color">Admotek Solutions</span>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6">
                    <ul className="footer-menu text-xs-center">
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    {displayScroll? <a href="#!" id="toTops" onClick={scrollTop} className="cd-top backtop-top-show"><i className="fa fa-angle-up"></i></a>:''}
</footer>
}


export default React.memo(Footer)