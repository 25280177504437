
import React from 'react';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Home from './home';
import Contact from './contact';
import About from './about';
import Donation from './donation';
import Directors from './directors';
import Management from './management';
import StaffDetails from './staff_details';
import Donors from './donors';
import Gallery from './gallery';
import GalleryDetails from './gallery_details';
import Programs from './events';
import ProgramsDetails from './events_details';
import Reports from './reports';
import Videos from './videos';
import Audios from './audios';
import News from './news';
import Career from './career';
import Error from './error';
import NewsDetails from './news_details';
import Registration from './registration';
import Flutter from './flutterwave';
function App() {
  
  return (<>

      <ReactNotification />
     <Router  >
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/donation" component={Donation} />
      <Route exact path="/about" component={About} />
      <Route exact path="/trustees" component={Directors} />
      <Route exact path="/donors" component={Donors} />
      <Route exact path="/staff_details" component={StaffDetails} />
      <Route exact path="/management" component={Management} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/gallery_details/:code" component={GalleryDetails} />
      <Route exact path="/events" component={Programs} />
      <Route exact path="/event_details/:code" component={ProgramsDetails} />
      <Route exact path="/videos" component={Videos} />
      <Route exact path="/reports" component={Reports} />
      <Route exact path="/career" component={Career} />
      <Route exact path="/news" component={News} />
      <Route exact path="/news_details/:code" component={NewsDetails} />
      <Route exact path="/audios" component={Audios} />
      <Route exact path="/payment" component={Flutter} />
      <Route exact path="/registration" component={Registration} />


      <Route exact component={Error} />
      </Switch>
      </Router>
        
</>
  );
}

export default App;
