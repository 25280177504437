
import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './_component/include';
import Template from './_component/template';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { getTime, longDate } from './_component/globalFunction';

const NewsDetails =()=> {
    const [gallery, setGallery] = useState([])
    const [content, setContent] = useState([])
    const [events, setEvents] = useState([])
    const [category, setCategory] = useState([])

let params = useParams()
    const fetchContent =()=>{
        var sql = "select  title, message, imageUrl, categories, comment, poster, datePosted, ID, readCount from tbl_news where status ='true' and ID ="+params.code

      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>{
        if(Array.isArray(result.data)){
            setContent(result.data)
        }
      })
  }

  

  const fetchEvents =()=>{
      var sql = "select imageUrl, title, startTime, caption, description, fromDate, toDate, location, code, ID from tbl_events order by ID DESC limit 3"
    let url = ServerUrl+'/fetchBySql_controller.php';
    const fd = new FormData();
        fd.append('sql', sql)
    axios.post(url, fd, config).then(result=>setEvents(result.data))
}


const fetchGallery =()=>{
    var sql = "select caption, cover, ID, dateCreate, viewCount from tbl_gallery where status='true' order by ID DESC limit 5"
  let url = ServerUrl+'/fetchBySql_controller.php';
  const fd = new FormData();
      fd.append('sql', sql)
  axios.post(url, fd, config).then(result=>setGallery(result.data))
}

const fetchCategory =()=>{
    var sql = "select categories, count(*) as num from tbl_news group by categories"
  let url = ServerUrl+'/fetchBySql_controller.php';
  const fd = new FormData();
      fd.append('sql', sql)
  axios.post(url, fd, config).then(result=>setCategory(result.data))
}

const fetchCount =()=>{
    var sql = "update tbl_news set readCount = readCount+1  where  ID ="+params.code
  let url = ServerUrl+'/fetchBySql_controller.php';
  const fd = new FormData();
      fd.append('sql', sql)
  axios.post(url, fd, config)
}

  useEffect(()=>{
    fetchCount()
    fetchCategory()
    fetchGallery()
    fetchContent()
    fetchEvents()
 },[]);

  return (<Template title={content.length!==0? content[0].title :''}>
  

<Helmet>
          <title> {content.length!==0? content[0].title :''} Genius Skills Empowerment Initiative </title>
         <meta name="description" content={content.length!==0? content[0].caption:''}  />
        <meta property="og:image" content={content.length!==0? ServerImagesUrl+'/news/'+content[0].imageUrl:''} />  
    </Helmet>

  <div className="main-content section-padding">
        <div className="container">
            <div className="row">

            {content.length!==0?content.map((item, id)=> <div key={id}  className="col-md-8">
            <div className="causes-details-wrap">
                        <div className="causes-details-top">
                            <div className="causes-details-image mb10">
                                <img src={ServerImagesUrl+'/news/'+item.imageUrl} alt="" />
                            </div>
                            <div className="causes-details-top__text-content">
                                <h3 className="causes-details__title text-uppercase">{item.title}</h3>
                                <div className="causes-details-meta pdt20 pdb15">
                                    <span>By:  <span className="nv-color">{item.poster}</span></span>
                                    <span>View:  <span className="nv-color">{item.readCount}</span></span>
                                    <span>Comments: <span className="nv-color">{item.comment.length}</span></span>

                                    <span>Category: <span className="nv-color">{item.categories}</span></span>
                                </div>
                               
                                <p dangerouslySetInnerHTML={{__html:item.message}}></p>

                               
                            </div>
                        </div>
                        <div className="causes-details-bottom mt40">
                           
                           
                            <div className="causes-details-navigation mt35 mb30">
                            <a href={"/news_details/"+ Number(item.ID - 1)} className="previous-post">Previous Post</a>
                        <a href={`/news_details/`+ (Number(item.ID) + 1)} className="next-post pull-right">Next Post</a>
                            </div>
                        </div>
                    </div></div>):<div  className="col-md-8"></div>}

                
                <div className="col-md-4">
                    <aside className="sidebar">
                        <form className="search-form widget">
                            <input type="search" placeholder="Search" className="form-control search-form__input" />
                            <button type="submit" className="search-form__submit"><i className="fa fa-search base-color"></i> </button>
                        </form>
                      
                        <div className="widget">
                            <div className="widget__heading">
                                <h4 className="widget__title">OUR <span className="base-color">EVENTS</span></h4>
                            </div>
                            <div className="widget__text-content">
                                <div className="upcomming-event-carousel" id="upcomming-event-carousel">
                                    
                                    
                                {events.length!==0?events.map((item, id)=>   <div key={id} className="upcomming-event-carousel__item">
                                        <div className="image text-center">
                                            <a href={"/event_details/"+item.ID}><img className="event-thumbnail" src={ServerImagesUrl+'/events/'+item.imageUrl} alt="up" />
                                            <h4 className="upcomming-event-carousel__title">{item.title}</h4></a>

                                        </div>
                                        <div className="event-counter">
                                            <div id="event-counter-one" className="musica-counter-active" data-value-year="2018" data-value-month="9" data-value-day="28" data-value-zone="+10" ></div>
                                        </div>
                                    </div>):''}

                                </div>
                            </div>
                        </div>
                        <div className="widget">
                            <div className="widget__heading">
                                <h4 className="widget__title">LATEST <span className="base-color">ACTIVITIES</span></h4>
                            </div>
                            <div className="widget__text-content">
                                
                                
                            {gallery.length!==0?gallery.map((item, id)=>    
                            <div key={id} className="widget-latest-causes">
                                    <div className="widget-latest-causes__image-wrap">
                                        <a href={'/gallery_details/'+item.ID}><img className="widget-latest-causes__thubnail" src={ServerImagesUrl+'/gallery/'+item.cover} alt="" /></a>
                                    </div>
                                    <div className="widget-latest-causes__text-content">
                                        <h4 className="widget-latest-causes__title"><a href={'/gallery_details/'+item.ID}>{item.caption}</a></h4>
                                        <div className="widget-latest-causes__admin small-text">
                                            <i className="base-color fa fa-eye widget-latest-causes__admin-icon"></i>{item.viewCount}
                                        </div>
                                        <div className="widget-latest-causes__time text-mute">
                                            {longDate(item.dateCreate)}
                                        </div>
                                    </div>
                                </div>):''}

                            </div>
                        </div>

                        <div className="widget widget--category">
                            <div className="widget__heading">
                                <h4 className="widget__title">CATAGORIES</h4>
                            </div>
                            <div className="widget__text-content">
                                <ul>
                                {category.length!==0?category.map((item, id)=>   <li key={id}><a href="#" className="pdr10">{item.categories}</a><span className="post-count">{item.num}</span></li>

                                ):''}
                                </ul>

                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>

</Template>
  );
}

export default NewsDetails;
