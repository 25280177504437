import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './include';

const Partners =()=> {

    const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "select imageUrl, title from tbl_partners "
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>{
          if(Array.isArray(result.data)){
             
          setContent(result.data)
      }
      })
  }

  useEffect(()=>{
    fetchContent()
 },[]);

return  <section className="sponser-section section-padding ash-white-bg">
<div className="container">
    <div className="row">
        <div className="col-md-12">
            <div className="section-heading text-center">
                <h2 className="section-title wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>OUR  <span className="base-color yellow">GEMS</span></h2>
                <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s" >Other organizations we work together to solve the problems</span>
                <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}></div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12">
            <div className="client-carusel wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".3s" style={{visibility: 'visible', animationDuration: '0.7s', animationDelay: '0.3s', animationName: 'fadeInUpXsd'}}>
              
            {content.length!==0?content.map((item, id)=> 
                <div key={id} className="carusel-item">
                 <img src={ServerImagesUrl+'/gems/'+item.imageUrl} alt={item.title} /> 
                </div>):''} 


            </div>
        </div>
    </div>
</div>
</section>}



export default Partners;