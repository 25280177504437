import React from 'react';
import { ngoImagesUrl } from './_component/include';
import Template from './_component/template';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
const Flutter =()=> {
  
    const config = {
        public_key: 'FLWPUBK-1f9d8e7c1f161bcdb997da08d6916016-X',
        tx_ref: Date.now(),
        amount: 5000,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: ' genskillshouse@gmail.com',
          phonenumber: '08032950881',
          name: 'Gen Skills',
        },
        customizations: {
          title: 'Genuis Skills Empowerment Initiatives',
          description: 'Donations',
          logo: 'https://genskilzinitiative.org/images/logo.jpg',
        },
      };
    
      const handleFlutterPayment = useFlutterwave(config);

  return (<Template title='Payment Platform' >
  




  <section className="section-padding ash-white-bg">
        <div className="container">
         
            <div className="row ">
  <div className="col-md-12 ">
  <button className='btn'
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
               console.log(response);
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
          });
        }}
      >
        Direct (5000) Donation
      </button>

                </div>

                    </div>
                </div>
                </section>


</Template>
  );
}

export default Flutter;
