import React, {useState, useRef} from 'react'
import axios from 'axios'
import { ServerUrl, config } from './include';

export const NewsLeter =()=> {

    const subRef = useRef(null) 
	const [notice, setNotice] = useState({
        isLoading : false
	});
	
	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState({});
	const handleChange = event =>{
		const {name, value} = event.target
       setEmail(value);
	}
	let emailAddress = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
	
	const handleSubmit = (event) =>{
		event.preventDefault();
		
        
        if(!emailAddress){
			var error ='Please enter valid email address';
			setErrors({...errors, email:error})
			alert(error) 
			subRef.current.focus();
        }else{
			
			setNotice({...notice, isLoading: true})     
				   
				const fd = new FormData();
				fd.append("email", email)
				
				let url = ServerUrl+'/save_controller.php?tablename=tbl_subscriber';
				axios.post(url, fd, config)
				.then(response =>{       
					if (response.data.type ==='success'){     
                        alert(response.data.message)         
					setEmail('')
					}else{
                        alert(response.data.message)
						}       
				})
				.catch((error)=>{
                    alert(error.message)
					
				}).finally(()=>{
					setNotice({...notice, isLoading: false}) 
				})  

		}

	} 
	
const ValidateExistence=()=>{
	if(emailAddress){  
		const fd = new FormData();
		fd.append("data", email)
		let url = ServerUrl+'/existence_controller.php?tablename=tbl_subscriber&column=email';
		axios.post(url, fd, config).then(response=>{
			
			if(response.data===true){
				subRef.current.focus()
                alert(email+' already subscribed')  
				setEmail('');
				return response.data;
			}
	  })
	}
} 


return   <div className="col-md-4 col-sm-6 col-xs-12">
<div className="widget widget--footer widget--userfull-link">
    <div className="widget__heading">
        <h4 className="widget__title">NEWSLETTERS</h4>
    </div>
    <div className="widget__text-content">
        <p>Join our mailing list to get updates about our latest event and activities</p>
        <form className="newsletter-form">
        <input ref={subRef} className='form-control newsletter-form__input' name="email" type="text" placeholder="Your email address"    autoComplete="off" onBlur={()=>ValidateExistence()}  onChange={handleChange} value={email}/>

        {notice.isLoading? <button className="btn base-bg newsletter-form__submit" type="button"><span className="spinner-border spinner-border-sm mr-2" ></span>Loading...</button>:<button className="btn base-bg newsletter-form__submit" onClick={handleSubmit}name="subscribe">JOIN</button>}
        </form>
    </div>
</div>
</div>}