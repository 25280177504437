import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl, Currency } from './_component/include';
import { FormatNumber } from './_component/globalFunction';
import Template from './_component/template';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

const Donation =()=> {

    const [notice, setNotice] = useState({
        isLoading : false
    });
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [errors, setErrors] = useState({});
    
    let initials = {
        ref:Date.now(),
        amount:'',
        method:'Online',
        message:'',
        fullname:'',
        email:'',
        anonymous:'No',
        telephone:'',
        organization:'',
        puzzle:'',
        num1:'',
        num2:''
    }

    const [donate, setDonation] = useState(initials)

    const generatePuzzle =()=>{
            var num1 = Math.floor(Math.random() * 9)
            var num2 = Math.floor(Math.random() * 9)
            var answer = num1+num2
            setDonation({...initials, num1:num1, num2:num2, answer:answer})
        
    } 

    const handleChange = event =>{
        const {name, value} = event.target
       setDonation({...donate, [name] : value });
       setErrors({...errors, [name]:''});
    }
    
    const handleSubmit = event =>{
        event.preventDefault();  
        setErrors(ValidateInput(donate));
            setIsSubmitting(true);
    } 


    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.fullname){
            errors.fullname ='Please enter your Name';
        }
        if(!values.telephone){
            errors.telephone ='Please enter your telephone';
        }
        if(!values.amount){
            errors.amount ='Please enter amount';
        }
        if(!Number(values.amount) || Number(values.amount)<=0){
            errors.amount ='Please enter valid amount';
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

        if(!values.puzzle){
            errors.puzzle ='Please provide answer';
        }else if(Number(values.puzzle) !== Number(values.answer)){
            errors.puzzle ='Answer provided to puzzle is not correct';
        }
        return errors;
        }
    
    useEffect(()=>{
        
        if(Object.keys(errors).length === 0 && isSubmitting){
        
       if(donate.method==='Online'){
           document.getElementById('makePayment').click()
       } 

            submit()
        }
        },[errors, isSubmitting])
    
        function submit(){        
            setNotice({...notice,  isLoading: true})     
             
            const fd = new FormData();
            for (let k in donate){
                fd.append(k, donate[k])
            } 
            let url = ServerUrl+'/save_controller.php?tablename=tbl_donation';
            axios.post(url, fd, config)
            .then(response =>{    
                 if (response.data.type ==='success'){ 
                  //  alert(response.data.message)             
                 
              generatePuzzle();
                }else{
                    alert(response.data.message)  
                    }       
            })
            .catch((error)=>{
                alert(error.message)  
                
            }).finally(()=>{
                setNotice({...notice,  isLoading: false  }) 
                setIsSubmitting(false);
                setDonation(initials)
            })   
        }

        
    useEffect(()=>{
        generatePuzzle();
        }, [])


        const flutterConfig = {
            public_key: 'FLWPUBK-1f9d8e7c1f161bcdb997da08d6916016-X',
            tx_ref: donate.ref,
            amount: Number(donate.amount),
            currency: "NGN",
            country: "NG",
            payment_options: 'card,mobilemoney,ussd',
            customer: {
              email: donate.email,
              phonenumber: donate.telephone,
              name: donate.fullname,
            },
            customizations: {
              title: 'Genuis Skills Empowerment House',
              description: 'Donations',
              logo: 'https://genskilzinitiative.org/images/logo.jpg',
            },
          };
        
          const handleFlutterPayment = useFlutterwave(flutterConfig);

  return (<Template title='Donation'>
  
  <div className="main-content section-padding">
        <div className="container">
            <h4 className="donate-page-title text-uppercase">Donation Details </h4>
            <form className="donate-page-form" >
                <div className="row pdb20">
                    <div className="col-md-3 pdb10">
                        <span>Donation Amount <span className="red-color">*</span> </span>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-xs-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="pay-5"  value="5000" name="amount"  onChange={handleChange} />
                                    <label htmlFor="pay-5">{Currency+' '+FormatNumber(5000)}</label>
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="pay-10" name="amount"  onChange={handleChange} value="50000" />
                                    <label htmlFor="pay-10">{Currency+' '+FormatNumber(50000)} </label>
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="pay-20" name="amount"  onChange={handleChange} value="100000" />
                                    <label htmlFor="pay-20">{Currency+' '+FormatNumber(100000)} </label>
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="pay-50" name="amount"  onChange={handleChange} value="200000" />
                                    <label htmlFor="pay-50">{Currency+' '+FormatNumber(200000)} </label>
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="pay-100" name="amount"  onChange={handleChange} value="500000" />
                                    <label htmlFor="pay-100">{Currency+' '+FormatNumber(500000)} </label>
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="pay-200" name="amount"  onChange={handleChange} value="1000000" />
                                    <label htmlFor="pay-200">{Currency+' '+FormatNumber(10000000)}</label>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mt15 input-group--border-radius">
                            <input type="number" name="amount"  onChange={handleChange} value={donate.amount} placeholder="or enter your own donation amount, e.g: 1000" className="form-control input-group__input" />
                            <span className="spanerror">{errors.amount && errors.amount}</span>
                        </div>
                    </div>
                </div>
                <div className="row pdb30">
                    <div className="col-md-3 pdb10">
                        <span>Payment Mathod <span className="red-color">*</span> </span>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="payment-method-online" checked={donate.method==='Online'?true:false} name="method" onChange={handleChange} value="Online" />
                                    <label htmlhtmlFor="payment-method-online">Online payment</label>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4">
                                <div className="input-group__radio-wrap">
                                    <input type="radio" id="payment-method-offline" checked={donate.method==='Offline'?true:false} name="method" value="Offline" onChange={handleChange}  />
                                    <label htmlhtmlFor="payment-method-offline">Offline payment</label>
                                </div>
                            </div>
                           
                        </div>

                    </div>
                </div>
               
                <div className="row pdb20">
                    <div className="col-md-3 pdb10">
                        <span>Message </span>
                    </div>
                    <div className="col-md-9">
                        <div className="input-group">
                            <textarea className="form-control input-group__textarea" name='message' value={donate.message} onChange={handleChange} placeholder="Your custom message text....">{donate.message}</textarea>
                        </div>
                    </div>
                </div>
                <div className="row pdb20">
                    <div className="col-md-3 pdb10">
                        <span>Anonymous donation? </span>
                    </div>
                    <div className="col-md-9">
                        <div className="input-group__radio-wrap">
                            <input type="checkbox" id="anonymous-doantaion" checked={donate.anonymous==='Yes'?true:false} onChange={handleChange} name="anonymous" value="Yes" />
                            <label htmlhtmlFor="anonymous-doantaion" className="nv-color">CHECK THIS BOX TO HIDE YOUR PERSONAL INFO IN OUR DONATORS LIST</label>
                        </div>
                    </div>
                </div>
                <div className="row pdt30">
                    <div className="col-md-12">
                        <h4 className="nv-color text-uppercase">Donator details</h4>
                    </div>
                </div>
                <div className="row donate-detail-form">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                        <input type="text" placeholder="Fullname *" name="fullname" value={donate.fullname}   onChange={handleChange} className={errors.fullname  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.fullname && errors.fullname}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                        <input type="email" className="form-control input-group__input" placeholder="Email *" name="email" value={donate.email}   onChange={handleChange} className={errors.email  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.email && errors.email}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                        <input type="text" className="form-control input-group__input" placeholder="Telephone" name="telephone" value={donate.telephone}   onChange={handleChange} className={errors.telephone  ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> <span className="spanerror">{errors.telephone && errors.telephone}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="input-group">
                        <input type="text" name="puzzle" value={donate.puzzle} placeholder={donate.num1 + ' + '+ donate.num2 + '  =?' } onChange={handleChange} className={errors.puzzle ? 'input-group__input form-control formerror' : 'input-group__input form-control'} /> 
        <span className="spanerror">{errors.puzzle && errors.puzzle}</span>	
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-12 col-xs-12">
                        <div className="input-group">
                            <input type="text" onChange={handleChange} name='organization' className="form-control input-group__input" placeholder="Organization" />
                        </div>
                    </div>
                </div>

              {donate.method==='Offline'?  <div className="row pdt30">
                    <div className="col-md-12">
                        <h4 className="nv-color text-uppercase">BANK details</h4>
                        <h3> Bank Name: ACCESS BANK <br/> <br/> Account Number: 0775235382 <br/><br/>  Account Name: GENIUS SKILLS EMPOWERMENT INITIATIVE</h3>
                    </div>
                </div>:''}

                <div className="row">
                    <div className="col-md-12">
                        <div className="input-group">
                        
                        <button type="button" style={{display:'none'}} id='makePayment' className='btn'
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
               console.log(response);
               //handleSubmit()
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},

          });
        }}
      >
        Save & Continue
      </button>
        <input type="button" onClick={handleSubmit} className="btn" value="Submit" />
                        </div>

                      

                    </div>
                </div>
            </form>
        </div>
    </div>

</Template>
  );
}

export default Donation;
