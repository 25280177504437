import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './_component/include';
import Template from './_component/template';

const Managements =()=> {
  
    const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "select imageUrl, code, fullname, profile, displayOrder, position from tbl_staff order by displayOrder ASC "
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }

  useEffect(()=>{
    fetchContent()
 },[]);

  return (<Template title='Management Staff'>
  
  <section className="volunteer-section section-padding ash-white-bg">
        <div className="container">
         
            <div className="row row-eq-rs-height">


            {content.length!==0?content.map((item, id)=>    <div key={id} className="col-md-4 col-sm-6 col-xs-12">
                    <div className="fund-volunteer text-center wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}>
                        <div className="fund-volunteer__photo-wrap">
                            <img src={ServerImagesUrl+'/people/'+item.imageUrl} className="img-circle fund-volunteer__photo" alt={item.fullname} style={{height:'150px', width:'150px'}} />
                        </div>
                        <div className="fund-volunteer__text-content">
                            <h3 className="fund-volunteer__name">{item.fullname.toUpperCase()}</h3>
                            <h5 className="yellow">{item.position.toUpperCase()}</h5>
                            <div className="section-heading-separator section-heading-separator--small"></div>
                            <p dangerouslySetInnerHTML={{__html:item.profile.slice(0,250)}}></p>
                            <a href="#" className="btn ">Read More</a>
                        </div>
                    </div>
                </div>):''}

            </div>
        </div>
    </section>

</Template>
  );
}

export default Managements;
