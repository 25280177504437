import React, {useState, useEffect} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ServerImagesUrl, ServerUrl, config } from './include';
import axios from 'axios'
//import Carousel from 'react-bootstrap/Carousel'

 const Carosel =()=>{
     
  const [content, setContent] = useState([])

    const fetchContent =()=>{
        var sql = "Select cover from tbl_gallery order by rand() limit 3 "
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>{
          if(Array.isArray(result.data)){

            setContent(result.data)
          }
      }
      )
  }


  useEffect(()=>{
    fetchContent()
 },[]);

      return    <div className="about-us-carousel mb30">
                       {content&&content.map((data, id)=>
                <div  key={id} >
                    <img className="card" style={{width:'600px', height:'400px'}}  src={ServerImagesUrl+"/gallery/"+data.cover} alt={id}  />
                </div>
                )}
                    </div> 
           
  }

export default React.memo(Carosel)