import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './_component/include';
import Template from './_component/template';
import { longDate } from './_component/globalFunction';

const Programs =()=> {
  
    const [content, setContent] = useState([])
    const [gallery, setGallery] = useState([])

    const fetchContent =()=>{
        var sql = "select imageUrl, title, startTime, caption, description, fromDate, toDate, location, code, ID from tbl_events order by ID DESC"
      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>setContent(result.data))
  }



  const fetchGallery =()=>{
      var sql = "select caption, cover, ID, dateCreate, viewCount from tbl_gallery where status='true' order by ID DESC limit 5"
    let url = ServerUrl+'/fetchBySql_controller.php';
    const fd = new FormData();
        fd.append('sql', sql)
    axios.post(url, fd, config).then(result=>setGallery(result.data))
}

  useEffect(()=>{
      fetchGallery()
    fetchContent()
 },[]);
  return (<Template title='Events'>
  


  <div className="main-content section-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    
                    <div className="fund-events">


                    {content.length!==0?content.map((item, id)=>   <div key={id} className="fund-event">
                            <div className="fund-event__date">
                                <span className="fund-event__day base-color">{item.fromDate.slice(8,10)}</span>
                                <span className="small-text nv-color">{longDate(item.fromDate)}</span>
                            </div>
                            <div className="fund-event__main">
                                <div className="fund-event__image-content">
                                    <div className="fund-event__image-wrap">
                                        <img src={ServerImagesUrl+'/events/'+item.imageUrl} className="fund-event__image" alt="" />
                                    </div>
                                    <div className="fund-event__text-content">
                                        <h4 className="fund-event__title"><a href={"/event_details/"+item.ID}>{item.title}</a></h4>
                                        <div className="fund-event__time">
                                            <i className="fa fa-calendar fund-event__icon base-color"></i>{item.fromDate +"- "+item.toDate}
                                        </div>
                                        <div className="fund-event__address">
                                            <i className="fa fa-map-marker fund-event__icon base-color"></i>{item.location}
                                        </div>
                                    </div>
                                </div>
                                <p >{item.caption}</p>
                            </div>
                        </div>):''}

                    </div>
                    <div className="fund-pagination mb30">
                        <a href="#" className="active">1</a>
                        <a href="#">2</a>
                        <a href="#" className="next">Next</a>
                    </div>
                </div>


                <div className="col-md-4">
                    <aside className="sidebar">
                        <form className="search-form widget">
                            <input type="search" placeholder="Search" className="form-control search-form__input" />
                            <button type="submit" className="search-form__submit"><i className="fa fa-search base-color"></i> </button>
                        </form>
                      
                        <div className="widget">
                            <div className="widget__heading">
                                <h4 className="widget__title">LATEST <span className="base-color">ACTIVITIES</span></h4>
                            </div>
                            <div className="widget__text-content">
                                
                                
                            {gallery.length!==0?gallery.map((item, id)=>    
                            <div key={id} className="widget-latest-causes">
                                    <div className="widget-latest-causes__image-wrap">
                                        <a href={'/gallery_details/'+item.ID}><img className="widget-latest-causes__thubnail" src={ServerImagesUrl+'/gallery/'+item.cover} alt="" /></a>
                                    </div>
                                    <div className="widget-latest-causes__text-content">
                                        <h4 className="widget-latest-causes__title"><a href={'/gallery_details/'+item.ID}>{item.caption}</a></h4>
                                        <div className="widget-latest-causes__admin small-text">
                                            <i className="base-color fa fa-eye widget-latest-causes__admin-icon"></i>{item.viewCount}
                                        </div>
                                        <div className="widget-latest-causes__time text-mute">
                                            {longDate(item.dateCreate)}
                                        </div>
                                    </div>
                                </div>):''}

                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>

</Template>
  );
}

export default Programs;
