import React from 'react';
import { Header } from './_component/header';
import { MobileNav } from './_component/mobileNav';
import { Navbar } from './_component/nav';
import Slider from './_component/slider';
import Carousel from './_component/carousel';
import { ngoImagesUrl} from './_component/include';
import  Footer  from './_component/footer';
import { Testimonials } from './_component/testimonial';
import { Adverts } from './_component/adverts';
import  Partners  from './_component/partners';
import Activity from './_component/activity';
import Targets from './_component/targets';
import Events from './_component/events';
import Sliders from './_component/sliders';

   
const Home =()=> {
    
   
  return (<>
     
    <Header />
   <MobileNav />

    <div className="slider-area">
        <Navbar />
        <Sliders />
    </div>



   <div className="body-overlay"></div>

   
   <section className="section-padding ash-white-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading text-center">
                        <h2 className="section-title yellow" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>OUR  <span className="base-color">OBJECTIVES</span> </h2 >
                        <span className="section-sub-title" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>At Genius Skills Empowerment Initiatives! We sustained lives with bare hands.</span>
                        <div className="section-heading-separator"></div>
                    </div>
                </div>
            </div>
            <div className="row">

                
                <div className="col-md-6 " >
                
                            <Carousel  />
                            </div> 
               

                <div className="col-md-6">
                    <div className="about-us-text-content mb30">
                        <h4>WE ARE NON FOR PROFIT ORGANIZATION</h4>
                        <p>We also partner with Individual philanthropists,
                                Schools, Religious bodies, Government & Corporate
                                organizations to run our entrepreneurship
                                and empowerment skill acquisition training
                                in various capacities. Our objectives includes:</p>
                        <ul className="fund-arrow-left-list pdb10">
                        <li>To diversify the dependency on oil through innovative and creative ideas embedded in skills acquisition.</li>
                            <li>To stimulate our economy through job creation.</li>
                            <li>To promote and encourage patronage of made in nigeria goods.</li>
                            <li>To reduce the prevalent poverty/suicidal rates in our country.</li>
                            <li>To reduce dependency on white collar jobs and encourage hand skills.</li>

                            <li>To ensure that many jobless/helpless youths are gainfully engaged thereby reducing  crime rate.</li>
                        </ul>
                        <p>We also assist individuals, develop and grow by helping them tackle
whatever challenges they face and in turn create jobs and
stimulate the economy..</p>
                    </div>
                </div>
            </div>
        </div>
    </section>





    <section className="section-padding feature-section">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading text-center">

                        <h2 className="section-title wow fadeInUpXsd yellow" data-wow-duration=".7s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.7s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>OUR <span className="base-color">PROGRAMS</span> </h2>



                        <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '0.9s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}>Our services are segmented into the following areas:</span>

                        <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1.1s', animationDelay: '0.1s', animationName: 'fadeInUpXsd'}}></div>
                    </div>
                </div>
            </div>
            <div className="row row-eq-rs-height">
            <div className=" col-sm-6 col-xs-12">
                    <div className="feature-item wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".6s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                        <div className="feature-item__icon-wrap">
                            <i className="feature-item__icon fa fa-heartbeat"></i>
                        </div>
                        <h4 className="feature-item__title heading-label-four"><a href="#">SHORT TERM SKILL APPRECIATION TRAINING</a></h4>
                        <p>We organize skills appreciation courses/trainings for groups of people in churches, mosques and in other places where our services are needed. However such trainees are not empowered immediately because of the short term nature of this training. Afterwards, the trainees may decide to benefit from the comprehensive and certified trainings that come with the empowerment. </p>
                    </div>
                </div>
                <div className=" col-sm-6 col-xs-12">
                    <div className="feature-item wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".4s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.4s', animationName: 'fadeInUpSmd'}}>
                        <div className="feature-item__icon-wrap">
                            <i className="feature-item__icon fa fa-search"></i>
                        </div>
                        <h4 className="feature-item__title heading-label-four"><a href="#">THE DISPLACED PERSONS' PROGRAM DPP</a></h4>
                        <p>This program is our core project, where we offer free skills training/empowernment to the vulnerable such as the internally displaced persons (IDPs), the returnees/deportees who initially embarked on dangerous trip abroad in search of greener pastures, victims of callous and greedy human traffickers, domestic violence victims, touts etc. </p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="feature-item wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}>
                        <div className="feature-item__icon-wrap">
                        <i className="feature-item__icon fa fa-female"></i><i className="feature-item__icon fa fa-male"></i>
                        </div>
                        <h4 className="feature-item__title heading-label-four"><a href="#">YOUTH & WOMEN PROGRAM (YWP)</a></h4>
                        <p>Here we focus on the teeming youths, such as students from secondary, tertiary institions and corps members. Women who desire to learn skill also come under this program. </p>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="feature-item wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".4s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.4s', animationName: 'fadeInUpSmd'}}>
                        <div className="feature-item__icon-wrap">
                            <i className="feature-item__icon fa fa-briefcase"></i>
                        </div>
                        <h4 className="feature-item__title heading-label-four"><a href="#">THE DISABLE PROGRAM(DP)</a></h4>
                        <p>Under this project, we take the skills training and eventual empowernment to them in their domain considering their disability. </p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="feature-item wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".6s" style={{visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.6s', animationName: 'fadeInUpSmd'}}>
                        <div className="feature-item__icon-wrap">
                            <i className="feature-item__icon fa fa-home"></i>
                        </div>
                        <h4 className="feature-item__title heading-label-four"><a href="#">CAPACITY BUILDING</a></h4>
                        <p>Organizations and cooporate bodies can take advantage of this program to train and equip their employees for not only life after retirement but also, to help them build other streams of income. </p>
                    </div>
                </div>
               
            </div>
        </div>
    </section>

    <section className="help-us-section section-padding all-text-white" style={{ backgroundImage: `url(${ngoImagesUrl+'/slider/2.JPG'})`}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading text-center">
                        <h2 className="section-title wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1.7s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}>HOW CAN YOU HELP ? </h2>
                        <span className="section-sub-title wow fadeInUpXsd disinb" data-wow-duration=".9s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1.7s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}>We’re On A Mission To Solve The Problems</span>
                        <div className="section-heading-separator wow fadeInUpXsd" data-wow-duration="1.1s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1.7s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <div className="help-us wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".2s" style={{visibility: 'visible', animationDuration: '1.7s', animationDelay: '0.2s', animationName: 'fadeInUpSmd'}}>
                        <div className="help-us__icon-wrap">
                            <i className="help-us__icon fa fa-users"></i>
                        </div>
                        <div className="help-us__text-content">
                            <h4 className="help-us__title heading-label-four">BECOME VOLUNTEER</h4>
                            <p>You can make a difference, become our volunteer with whatever you have wherever you are.</p>
                            <a href="/career" className="btn">JOIN US</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12">
                    <div className="help-us wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".4s">
                        <div className="help-us__icon-wrap">
                            <i className="help-us__icon fa fa-money"></i>
                        </div>
                        <div className="help-us__text-content">
                            <h4 className="help-us__title heading-label-four">BECOME DONATOR</h4>
                            <p>Your donation will help us empower more community members thereby reducing crime rate</p>
                            <a href="/donation" className="btn">DONATE NOW</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12">
                    <div className="help-us wow fadeInUpSmd" data-wow-duration="1.5s" data-wow-delay=".6s">
                        <div className="help-us__icon-wrap">
                            <i className="help-us__icon fa fa-thumbs-up"></i>
                        </div>
                        <div className="help-us__text-content">
                            <h4 className="help-us__title heading-label-four">BECOME PARTNER</h4>
                            <p>You are increasing your competitiveness by starting a partnership with us through our support services</p>
                            <a href="/contact" className="btn">CONTACT US</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<Partners />

<Adverts />

<Activity />
<Targets />
<Events />
   <Testimonials />
<Footer />
        
</>
  );
}

export default Home;
