
import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { ServerUrl, config, ServerImagesUrl } from './_component/include';
import Template from './_component/template';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { getTime, longDate } from './_component/globalFunction';

const ProgramDetails =()=> {


    const [settings, setSettings] = useState([])
    const [gallery, setGallery] = useState([])
    const [content, setContent] = useState([])
let params = useParams()
    const fetchContent =()=>{
        var sql = "select imageUrl, title, startTime, caption, description, fromDate, toDate, location, ID, code from tbl_events where ID ="+params.code

      let url = ServerUrl+'/fetchBySql_controller.php';
      const fd = new FormData();
          fd.append('sql', sql)
      axios.post(url, fd, config).then(result=>{
        if(Array.isArray(result.data)){
            setContent(result.data)
        }
      })
  }


  
  const fetchSettings =()=>{
    let url = ServerUrl+'/fetch_controller.php';
    const fd = new FormData();
        fd.append('tablename', 'tbl_settings')
    axios.post(url, fd, config).then(result=>{
        if(Array.isArray(result.data)){

          setSettings(result.data[0])
        }
    }
    )
}


const fetchGallery =()=>{
    var sql = "select caption, cover, ID, dateCreate, viewCount from tbl_gallery where status='true' order by ID DESC limit 5"
  let url = ServerUrl+'/fetchBySql_controller.php';
  const fd = new FormData();
      fd.append('sql', sql)
  axios.post(url, fd, config).then(result=>setGallery(result.data))
}

  useEffect(()=>{
    fetchSettings()
    fetchGallery()
    fetchContent()
 },[]);

  return (<Template title='Events'>
  

<Helmet>
          <title> Genius Skills Empowerment Initiative </title>
         <meta name="description" content={content.length!==0? content[0].title:''}  />
        <meta property="og:image" content={content.length!==0? ServerImagesUrl+'/events/'+content[0].imageUrl:''} />  
    </Helmet>

  <div className="main-content section-padding">
        <div className="container">
            <div className="row">
        {content.length!==0?content.map((item, id)=> <div key={id} className="col-md-8">
                    <div className="event-detial-wrap">
                        <div className="image-wrap mb40">
                            <img src={ServerImagesUrl+'/events/'+item.imageUrl} className="img-round" alt="" />
                        </div>
                        <h3 className="pdb10">{item.title}</h3>
                        <p >{item.caption}</p>
                        <ul className="event-detail-meta nv-color">
                            <li>
                                <span className="event-detail-meta__title"><i className="fa fa-calendar base-color icon"></i> Event Schedule</span>
                                <span className="desc">{item.fromDate +"- "+item.toDate} {getTime(item.startTime)}</span>
                            </li>
                            <li>
                                <span className="event-detail-meta__title"><i className="fa fa-map-marker base-color icon"></i> Location</span>
                                <span className="desc">{item.location}</span>
                            </li>
                            
                        </ul>
                    </div>
                    <div>
                    <p dangerouslySetInnerHTML={{__html:item.description}}></p>
                    </div>
                 
                    <div className="event-details-location-wrap mt40 pdt50">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="location-text-content mb30">
                                    <h3>CONTACT INFO</h3>
                                    <p>See where to locate us</p>
                                    <ul>
                                        <li><span className="loc-icon"><i className="fa fa-map-marker base-color"></i></span>{settings&&settings.address} </li>
                                        <li><span className="loc-icon"><i className="fa fa-phone base-color"></i></span>
                            {settings&&settings.telephone} - {settings&&settings.whatsapp}</li>
                                        <li><span className="loc-icon"><i className="fa fa-ticket base-color"></i></span>{settings&&settings.email}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div id="mapContainer">

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.0354081348905!2d7.319893814571642!3d9.151274789582116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104dd8e50790d20b%3A0x5e8db41804193390!2sSultan%20Dasuki%20Rd%2C%20Kubwa%2C%20Abuja!5e0!3m2!1sen!2sng!4v1615837383627!5m2!1sen!2sng" width="400" height="200"  allowfullscreen="" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="blog-details-navigation mt25 pdb30">
                        <a href={"/event_details/"+ Number(item.ID - 1)} className="previous-post">Previous Post</a>
                        <a href={`/event_details/`+ (Number(item.ID) + 1)} className="next-post pull-right">Next Post</a>
                    </div>
                </div>):<div  className="col-md-8"></div>}
                
                <div className="col-md-4">
                    <aside className="sidebar">
                        <form className="search-form widget">
                            <input type="search" placeholder="Search" className="form-control search-form__input" />
                            <button type="submit" className="search-form__submit"><i className="fa fa-search base-color"></i> </button>
                        </form>
                      
                        <div className="widget">
                            <div className="widget__heading">
                                <h4 className="widget__title">LATEST <span className="base-color">ACTIVITIES</span></h4>
                            </div>
                            <div className="widget__text-content">
                                
                                
                            {gallery.length!==0?gallery.map((item, id)=>    
                            <div key={id} className="widget-latest-causes">
                                    <div className="widget-latest-causes__image-wrap">
                                        <a href={'/gallery_details/'+item.ID}><img className="widget-latest-causes__thubnail" src={ServerImagesUrl+'/gallery/'+item.cover} alt="" /></a>
                                    </div>
                                    <div className="widget-latest-causes__text-content">
                                        <h4 className="widget-latest-causes__title"><a href={'/gallery_details/'+item.ID}>{item.caption}</a></h4>
                                        <div className="widget-latest-causes__admin small-text">
                                            <i className="base-color fa fa-eye widget-latest-causes__admin-icon"></i>{item.viewCount}
                                        </div>
                                        <div className="widget-latest-causes__time text-mute">
                                            {longDate(item.dateCreate)}
                                        </div>
                                    </div>
                                </div>):''}

                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>

</Template>
  );
}

export default ProgramDetails;
