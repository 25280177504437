
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 

export const Currency = '₦'

export const ServerUrl = "https://genskilzinitiative.org/controller"
export const ServerImagesUrl = "https://admin.genskilzinitiative.org/images"
export const ngoImagesUrl = "https://genskilzinitiative.org/images"  
  
