
import Template from './_component/template';

const Error =()=> {
  
  return (<Template title='Error'>


  <section className="section-padding ash-white-bg">
        <div className="container">
         
            <div className="row ">
  <div className="col-md-12 ">
                   <h2> Oops we cant find what you are looking for</h2>
                </div>

                    </div>
                </div>
                </section>


</Template>
  );
}

export default Error;
