import React from 'react';
import { ngoImagesUrl } from './include';


export const Adverts =()=> <div className="join-us-section all-text-white" style={{backgroundImage:`url(${ngoImagesUrl+'/others/back.jpg'})`}}>
<div className="container">
    <div className="row">
        <div className="col-md-12">
            <div className="join-us wow fadeInUpXsd" data-wow-duration=".7s" data-wow-delay=".3s">
                <div className="join-us__text-content">
                    <h4 className="join-us__title">DO YOU WANT TO JOIN OUR ONGOING SKILL ACQUISATION PROGRAM?</h4>
                    <p>Available skills are Cake & Pastries, Shoe/Bag Making, Photography, Fashion Design, Tie & Die, General Printing, Carpentary, Bee Keeping, Fishery, Snailry, Plumbing, Bead Making, POP Design, Cinematography, Event Planning & Decoration, Make-Up and many more. </p>
                </div>
                <div className="pdb30 pdt10">
                    <a href="/registration" className="btn">CLICK HERE TO REGISTER</a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>